<template>
    <div>


      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          id="Scroll"
          pagination
          :max-items="itemsPerPage"
          search
          :data="AdsManagementdata"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >

            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    AdsManagementdata.length - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : AdsManagementdata.length
                  }}
                  of {{ AdsManagementdata.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <template slot="thead">
            <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="Name">{{ $t("Name") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="Name">{{ $t("Address") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="IdeNumber">{{ $t("IdeNumber") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="PhoneNumber">{{ $t("PhoneNumber") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: #85a961;" sort-key="OwnerType">{{ $t("OwnerType") }}</vs-th>
            <!-- <vs-th sort-key="UNumberOfUint">{{ $t("UNumberOfUint") }}</vs-th>
            <vs-th sort-key="Status">{{ $t("Status") }}</vs-th> -->
            <vs-th  style="font-size: 16px;font-family:almarai ; color:crimson;"> {{ $t("Reject") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;"> {{ $t("Approve") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click="editData(tr)"
              >
                <vs-td>
                  <p style="font-size: 12px;font-family:almarai ;">
                    {{ tr.name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p style="font-size: 12px;font-family:almarai ;">
                    {{ tr.permanentAddress }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.nationalCardNumber }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.PhoneNumber }}
                  </p>
                  <p class="product-name font-medium truncate">
                    {{ tr.additionalPhoneNumber }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    <span v-if="tr.unitOwnerType && tr.unitOwnerType.nameAr">{{
                      tr.unitOwnerType.nameAr
                    }}</span>
                    <span v-else>N/A</span>
                  </p>
                </vs-td>
                <!-- <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.unitData ? tr.unitData.length : 0 }}
                  </p>
                </vs-td>
                <vs-td>
                  <vs-switch
                    :style="{ color: tr.isActive ? 'green' : 'red' }"
                    :checked="tr.isActive"
                  />
                </vs-td> -->
                <vs-td class="whitespace-no-wrap">
                    <vx-tooltip :text="'رفض'" position="top" delay=".3s">
                  <x-icon

                    icon="CheckIcon"
                    style="color: red"
                    class="hover-green"
                    svgClasses="w-10 h-5 hover:text-primary stroke-current"
                    @click.stop="CancelRequest(tr)"
                  />
                </vx-tooltip>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <vx-tooltip :text="'قبول الانضمام'" position="top" delay=".3s">

                  <check-circle-icon

                    icon="XIcon"
                    style="color: green"
                    class="hover-red"
                    svgClasses="w-10 h-5 hover:text-primary stroke-current"
                    @click.stop="ApprovalRequest(tr)"
                  />
                </vx-tooltip>



                </vs-td>

              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </template>

  <script>
  import { domain } from "@/gloabelConstant.js";
  import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";
  import moduleUintOwnerType from "@/store/UintOwnerType/moduleUintOwnerType.js";
  import moduleUintModel from "@/store/UintModel/moduleUintModel.js";
  import { CheckCircleIcon } from 'vue-feather-icons'
  import { XIcon } from 'vue-feather-icons'
//   import { UserXIcon } from 'vue-feather-icons'
//   import { UserCheckIcon } from 'vue-feather-icons'

  export default {
    components: {
       XIcon,
   CheckCircleIcon
    },
    data() {
      return {
        itemsPerPage: 10,
        search: {
          name: "",
          nationalId: "",
          phoneNumber: "",
          unitNumber: 0,
          unitOwnerTypeId: 0,
          unitModelId: 0,
          pageNumber: 0,
          pageSize: 20,
        },
        filterFields: [
          { label: "FullName", model: "name", name: "FullName" },
          { label: "IdeNumber", model: "nationalId", name: "IdeNumber" },
          { label: "PhoneNumber", model: "phoneNumber", name: "PhoneNumber" },
          // {
          //   label: "OwnerType",
          //   model: "unitOwnerTypeId",
          //   name: "nameAr",
          //   isDropdown: true,
          //   options: this.ownerTypeOptions,
          // },
          // {
          //   label: "UnitModel",
          //   model: "unitModelId",
          //   name: "UnitModel",
          //   isDropdown: true,
          //   options: this.unitModelOptions,
          // },
        ],
        ownerTypeOptions: [],
        unitModelOptions: [],
        AdsManagementdata: [],
        baseURL: domain,
        isBlock: "",
        isBlocked: ""
      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
       AdsManagements() {
         return this.$store.state.AdsManagementList.AdsManagements;
       },
    },

    methods: {
      fetchOwnerTypeOptions() {
        debugger;
        this.$store
          .dispatch("UintOwnerTypeList/fetchDataListItems")
          .then((res) => {
            debugger;
            this.ownerTypeOptions = res.data.data;
          })
          .catch((error) => {
            console.error("Error fetching owner type options:", error);
          });
      },
      fetchmUintModelOptions() {
        debugger;
        this.$store
          .dispatch("moduleUintModelList/fetchDataListItems")
          .then((res) => {
            debugger;
            this.unitModelOptions = res.data.data;
          })
          .catch((error) => {
            console.error("Error fetching unit model options:", error);
          });
      },
      handleSearch() {
        debugger;
        this.$store
          .dispatch("AdsManagementList/GetOwnersNotApproved")
          .then((res) => {
            debugger;
            this.AdsManagementdata = res.data.data;
          });
      },
      AddOrEditData(data) {
        debugger;
        if(data.id == undefined){
          data.id = 0;
        }
        this.$router.push(`/AdsManagement/AddAdsManagement/${data.id}`);
      },
      AcceptRequests() {
      this.$router.push('/AdsManagement/AcceptRequests/');
  },

      openConfirm(data) {
        debugger;
        this.rowDataForDelete = data;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: this.$t("DeleteConfirmText"),
          accept: this.acceptAlert,
          acceptText: this.$t("Delete"),
          cancelText: this.$t("Cancel"),
        });
      },
      acceptAlert() {
        this.deleteData(this.rowDataForDelete);
        this.$vs.notify({
          color: "success",
          title: "Deleted record",
          text: this.$t("DeletedSuccessfully"),
        });

        this.handleSearch(this.search);
      },
      cancelsuccess() {
        this.$vs.notify({
          color: "success",
          title: "تم رفض طلب الانضمام",
        //  text: this.$t("CancelSuccessfully"),
        });
      },

      approvedsuccess() {
        this.$vs.notify({
          color: "success",
          title: "تم قبول طلب الانضمام بنجاح",
          //text: this.$t("ApprovedSuccessfully"),
        });
      },

      deleteData(data) {
        debugger;
        this.$store
          .dispatch("AdsManagementList/DeleteUnitOwner", data)

          .catch((err) => {
            console.error(err);
          });
      },
      CancelRequest(data) {
        debugger;
        //this.isBlocked = false;

          var Id =  data.id;

        this.$store
          .dispatch("AdsManagementList/CancelRequest", Id)
          .then(() => {
            this.cancelsuccess();
            this.handleSearch();
          });
      },

      ApprovalRequest(data) {
        debugger;
       // this.isBlock = true;

          var Id =  data.userID;

        this.$store
          .dispatch("AdsManagementList/ApprovalRequest", Id)
          .then(() => {
            debugger;
            this.approvedsuccess();
            this.handleSearch();
          });
      },
    },
    created() {
      if (!moduleUintOwnerType.isRegistered) {
        this.$store.registerModule("UintOwnerTypeList", moduleUintOwnerType);
        moduleUintOwnerType.isRegistered = true;
      }
      //moduleUintModel

      if (!moduleUintModel.isRegistered) {
        this.$store.registerModule("moduleUintModelList", moduleUintModel);
        moduleUintModel.isRegistered = true;
      }

      // this.$store.dispatch("UintOwnerTypeList/fetchDataListItems").then((res) => {
      //   debugger;
      //   this.ownerTypeOptions = res.data.data;
      // });

      if (!moduleAdsManagement.isRegistered) {
        this.$store.registerModule(
          "AdsManagementList",
          moduleAdsManagement
        );
        moduleAdsManagement.isRegistered = true;
      }

      this.handleSearch(this.search);
      this.fetchOwnerTypeOptions();
      this.fetchmUintModelOptions();
    },
    mounted() {
      this.isMounted = true;
      this.fetchOwnerTypeOptions();
      this.fetchmUintModelOptions();
    },
  };
  </script>

  <style lang="scss">
  #data-list-list-view small {
    color: rgb(255, 115, 0);
    text-decoration: underline rgb(255, 115, 0);
  }

  .vxCard:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    margin-left: 10%;
    z-index: 500000;
    width: 25%;
    height: 20%;
    border-top: 3px solid rgb(255, 115, 0) !important;
    border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
    border-right: 0 none rgba(255, 0, 0, 0.9) !important;
    border-left: 0 none rgba(0, 0, 0, 0.9) !important;
  }
  #data-list-list-view {
    .vs-con-table {
      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
          td {
            padding: 20px;
            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }
            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check {
          padding: 0 15px !important;
        }
        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
